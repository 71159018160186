<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Liste des devis">
          <vue-good-table-vue
            :columns="columns"
            :rows="rows"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'MainVue',
  components: {
    BRow, BCol, BCard, VueGoodTableVue,
  },
  data() {
    return {
      columns: [
        {
          label: 'Référence',
          field: 'reference',
        },
        {
          label: 'Secteur d\'activité',
          field: 'secteur',
        },
        {
          label: 'Arrondissement',
          field: 'commune',
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
        },
        {
          label: 'Montant',
          field: 'montant',
        },
        {
          label: 'Montant payé',
          field: 'montantPaye',
        },
        {
          label: 'Date limite',
          field: 'dateLimite',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
        },
        // {
        //   label: 'Livraison',
        //   field: 'livraison',
        // },
        {
          label: 'Point de vente',
          field: 'pointDeVente',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [
        {
          reference: 'D1001',
          secteur: 'Cosmétiques',
          commune: 'Arrondissement 1',
          date: '2023-02-01',
          montant: 250000,
          montantPaye: 100000,
          dateLimite: '2023-03-01',
          livraison: 'Oui',
          pointDeVente: 'Point de vente 1',
          action: 'Voir détails',
        },
        {
          reference: 'D1002',
          secteur: 'Agroalimentaire',
          commune: 'Arrondissement 3',
          date: '2023-02-02',
          montant: 125000,
          montantPaye: 50000,
          dateLimite: '2023-03-02',
          livraison: 'Non',
          pointDeVente: 'Point de vente 2',
          action: 'Voir détails',
        },
        {
          reference: 'D1003',
          secteur: 'Informatique',
          commune: 'Arrondissement 4',
          date: '2023-02-03',
          montant: 350000,
          montantPaye: 0,
          dateLimite: '2023-03-03',
          livraison: 'Oui',
          pointDeVente: 'Point de vente 3',
          action: 'Voir détails',
        },
        {
          reference: 'D1004',
          secteur: 'Équipements électriques',
          commune: 'Arrondissement 1',
          date: '2023-02-04',
          montant: 70000,
          montantPaye: 70000,
          dateLimite: '2023-03-04',
          livraison: 'Oui',
          pointDeVente: 'Point de vente 4',
          action: 'Voir détails',
        },
        {
          reference: 'V1005',
          secteur: 'Textile',
          commune: 'Plateau',
          date: '2023-02-05',
          montant: 1000000,
          montantPaye: 500000,
          dateLimite: '2023-03-05',
          livraison: 'Non',
          pointDeVente: 'Point de vente 5',
          action: 'Voir détails',
        },
      ],
    }
  },
}
</script>

<style scoped>

</style>
